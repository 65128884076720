body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.container-main {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.newClass {
  position: static !important;
  width: 100% !important;
  height: 100vh !important;
}
@media (max-width: 992px) {
  .newClass {
    height: 100% !important;
  }
}
.scrollTopButton {
  background-color: #fff;
  border-radius: 50%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.textTruncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.btnContainer button {
  margin-right: 10px;
}

.text-color-blue {
  background: #206bc4;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}
.text-color-orange {
  background: orange;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}
.text-color-yellow {
  background: #dfdf0d;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}
.text-color-red {
  background: red;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}
.text-color-green {
  background: green;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
}
.errror_page {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: #1e1e1e;
}
.error_image_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.error_image_box img {
  max-width: 600px;
}
